import React from "react";
import { StrapiFile } from "types/StrapiFile";
import { Modal, useWindowDimensions, View } from "react-native";
import { Button } from "../button/Button";
import { useSafeArea } from "providers/safe-area/use-safe-area";
import { Carousel } from "../carousel/Carousel";

type Props = {
  images: StrapiFile[];
  showImageSlider?: number;
  setShowImageSlider: any;
};

export const DetailImagesSlider = ({
  images,
  showImageSlider = -1,
  setShowImageSlider,
}: Props) => {
  const { width, height } = useWindowDimensions();
  const insets = useSafeArea();

  return (
    <Modal statusBarTranslucent visible={showImageSlider > -1} transparent>
      <Carousel width={width} height={height + insets.top} images={images} />
      <View
        style={{
          position: "absolute",
          top: 10 + insets.top,
          right: 20,
          width: 50,
        }}
      >
        <Button
          onPress={() => {
            setShowImageSlider(-1);
          }}
          type="action"
          iconType={"MaterialCommunity"}
          iconName={"close"}
          iconColorName="white"
          style={{ marginTop: 16 }}
          iconOnly
        />
      </View>
    </Modal>
  );
};
