import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    mainContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(50),
      backgroundColor: theme.colors.gradientStart,
      borderRadius: theme.normalize(25),
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
    },
    mainOutlinedContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(50),
      borderRadius: theme.normalize(25),
      borderWidth: theme.normalize(theme.sizings.tiny),
      borderColor: theme.colors.gradientStart,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
    },
    gradientIcon: {
      marginLeft: -theme.sizings.medium,
    },
    actionContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(50),
      backgroundColor: theme.colors.white,
      borderRadius: theme.normalize(25),
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      borderWidth: theme.normalize(theme.sizings.tiny),
      borderColor: theme.colors.gradientStart,
    },
    gradientContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(45),
      borderRadius: theme.normalize(22.5),
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      paddingHorizontal: theme.normalize(theme.sizings.mediumLarge),
      paddingVertical: theme.sizings.medium * 0.6,
    },
    disabledContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(45),
      borderRadius: theme.normalize(22.5),
      backgroundColor: theme.colors.grey,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      paddingHorizontal: theme.normalize(theme.sizings.mediumLarge),
      paddingVertical: theme.sizings.medium * 0.6,
    },
    iconOnlyContainer: {
      width: theme.normalize(50),
      height: theme.normalize(50),
      borderRadius: theme.normalize(25),
      backgroundColor: theme.colors.gradientStart,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      color: theme.colors.dark,
      fontWeight: "bold",
      fontSize: theme.normalizeFont(theme.fontSizes.large),
    },
    actionText: {
      color: theme.colors.dark,
      marginLeft: theme.normalize(theme.sizings.medium),
      marginRight: theme.normalize(theme.sizings.smallMedium),
    },
    gradientText: {
      color: theme.colors.gradientText,
      fontSize: theme.fontSizes.largeLow,
    },
    disabledText: {
      color: theme.colors.gradientText,
      fontSize: theme.fontSizes.largeLow,
    },
    iconContainer: {
      width: theme.normalize(50),
      height: theme.normalize(50),
      borderRadius: theme.normalize(25),
      backgroundColor: theme.colors.gradientStart,
      justifyContent: "center",
      alignItems: "center",
      marginRight: -1,
    },
    iconContainerGradient: {
      justifyContent: "center",
      alignItems: "center",
      marginRight: theme.sizings.smallMedium,
    },
  });
