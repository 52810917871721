import { JImage } from "../image";
import React from "react";
import { View } from "react-native";
import { StrapiFile } from "types/StrapiFile";

interface CarouselItemProps {
  image: StrapiFile;
  width: number;
  height: number;
}

export const CarouselItem = ({ image, width, height }: CarouselItemProps) => {
  return (
    <View style={{ width: width, height: height }}>
      <JImage
        alt="image"
        resizeMode="contain"
        source={image.url}
        style={{
          width: width,
          height: height,
        }}
      />
      <View style={{ flex: 1, width: width, height: height }} />
      {/* On a besoin de ce view pour le web pour pouvoir scroll sur l'image sinon ca fait comme si ca voulait la drag */}
    </View>
  );
};
