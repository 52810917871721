import { View } from "react-native";
import { useRef, useContext, createContext, useState } from "react";
import { CarouselItem } from "./CarouselItem";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { Zoom } from "./Zoom";
import RNCarousel, { ICarouselInstance } from "./rncarousel";
import { Button } from "../button";
import React from "react";
import { useSafeArea } from "providers/safe-area/use-safe-area";
import { StrapiFile } from "types/StrapiFile";

type CarouselContextType = {
  isZoomActive: boolean;
  setIsZoomActive: (state: boolean) => void;
};
export const CarouselContext = createContext<CarouselContextType>({
  isZoomActive: false,
  setIsZoomActive: () => {},
});

const CarouselProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isZoomActive, setIsZoomActive] = useState(false);
  return (
    <CarouselContext.Provider
      value={{
        isZoomActive,
        setIsZoomActive,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

interface CarouselComponentProps {
  images: StrapiFile[];
  width: number;
  height: number;
}

export const CarouselComponent: React.FC<CarouselComponentProps> = ({
  images,
  width,
  height,
}) => {
  const { isZoomActive, setIsZoomActive } = useContext(CarouselContext);
  const ref = useRef<ICarouselInstance>(null);
  const goToNext = () => {
    ref.current?.scrollTo({ count: 1, animated: true });
  };

  const goToPrevious = () => {
    ref.current?.scrollTo({ count: -1, animated: true });
  };

  const insets = useSafeArea();

  return (
    <GestureHandlerRootView>
      <View style={{ backgroundColor: "black", width: width, height: height }}>
        <RNCarousel
          ref={ref}
          width={width}
          height={height}
          loop={images.length > 1 ? true : false}
          data={images}
          enabled={!isZoomActive}
          //onProgressChange={progress}
          renderItem={({ item }) => (
            <View style={{ width: width, flex: 1, backgroundColor: "black" }}>
              <Zoom
                onZoomBegin={() => setIsZoomActive(true)}
                onZoomEnd={() => setIsZoomActive(false)}
                isZoomActive={isZoomActive}
              >
                <CarouselItem width={width} height={height} image={item} />
              </Zoom>
            </View>
          )}
        />

        <View
          style={{
            flex: 1,
            width: width,
            // height: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
            paddingTop: 30,
            paddingHorizontal: 30,
            paddingBottom: 30 + insets.bottom,
            position: "absolute",
            bottom: 0,
          }}
        >
          {isZoomActive ? (
            <Button
              iconName="search"
              label="Quitter le zoom"
              iconColorName="white"
              onPress={() => {
                setIsZoomActive(false);
              }}
            />
          ) : images.length > 1 ? (
            <>
              <Button
                iconName="keyboard-arrow-left"
                iconType="MaterialIcons"
                iconColorName="white"
                iconOnly
                disabled={isZoomActive}
                onPress={goToPrevious}
                style={{ width: 50, height: 50 }}
                type="action"
              />
              <Button
                iconName="keyboard-arrow-right"
                iconType="MaterialIcons"
                iconColorName="white"
                iconOnly
                disabled={isZoomActive}
                onPress={goToNext}
                style={{ width: 50, height: 50 }}
                type="action"
              />
            </>
          ) : null}
        </View>
      </View>
    </GestureHandlerRootView>
  );
};

interface CarouselProps {
  width: number;
  height: number;
  images: StrapiFile[];
}

export const Carousel: React.FC<CarouselProps> = ({
  width,
  height,
  images,
}) => {
  return (
    <CarouselProvider>
      <CarouselComponent images={images} width={width} height={height} />
    </CarouselProvider>
  );
};
